import React from "react"
declare global {
  interface Window {
    siteConfig: {
      clientId: string;
      userPoolId: string;
      graphQLAPI: string;
    };
  }
}

const Index = props => {
  console.log('index')
  return <></>
}

export default Index
